import request from '@/common/utils/request'
import Vue from "vue";

export function getSystemSet(data) {
	return request({
	  url: Vue.prototype.$baseUrl + '/api/system/get-system-setting',
    method: 'post',
	  data
	})
}
export function deleteUser(data) {
	return request({
	  url: Vue.prototype.$baseUrl + '/adminapi/plate/delete-plate',
    method: 'post',
	  data
	})
}
